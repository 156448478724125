import React, { useEffect, useState } from "react";
import { Wrapper } from "../styles";
import Breadcrumb from "../../../shared/Breadcrumbs";
import { Chip, Container, Box, Grid, Button } from "@mui/material";
import { getJiraProjectDetail } from "../../../httpRequests/jira";
import Loading from "../../../shared/Loading";
import Flags from "../../../shared/Flags";
import statusChip from "../../../utils/statusChip";
import { makeStyles } from "@material-ui/core/styles";
import {
  BookOpenIcon,
  UsersIcon,
  RocketLaunchIcon,
  BanknotesIcon,
  CodeBracketIcon,
  CalendarDaysIcon,
  PencilIcon,
  LinkIcon,
  CloudIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import JiraDescription from "../shared/JiraDescription";
import JiraLatestComment from "../../../shared/JiraLatestComment";
import { formatDate } from "../../../utils/checkDate";
import ProgressBars from "../../../shared/ProgressBar";
import Budget from "./Budget";
import PMO_DETAIL from "../../../constants/projectDetail";
import numberWithCommas from "../../../utils/numberWithCommas";

const useStyles = makeStyles(() => ({
  svg: {
    position: "relative",
    top: 0,
    marginRight: 5,
    width: 18,
  },
  edit: {
    width: 20,
  },
}));

const fields =
  "customfield_10193,customfield_11758,customfield_11843,customfield_11840,customfield_11785,customfield_11839,customfield_11899,customfield_11834,customfield_11842,customfield_11752,customfield_11833,customfield_11760,customfield_11837,customfield_11838,customfield_11835,customfield_11836,customfield_10015,customfield_11900,customfield_11845,customfield_11844,customfield_11841,summary,status,assignee,duedate,description,comment,subtasks";

const PMOProjectDetail = ({ id }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [totalBudget, setTotalBudget] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await getJiraProjectDetail(id, fields);
        setData(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    process.env.GATSBY_APP_ENV === "development"
      ? setData(PMO_DETAIL)
      : fetchData();
  }, [id]);

  const { fields: projectFields } = data || {};
  const {
    summary,
    customfield_11758,
    customfield_10193,
    customfield_11844,
    customfield_11842,
    customfield_11752,
    customfield_11760,
    customfield_11845,
    customfield_11834,
    customfield_11833,
    customfield_11837,
    customfield_11838,
    customfield_11835,
    customfield_11836,
    customfield_10015,
    customfield_11840,
    customfield_11899,
    customfield_11839,
    customfield_11785,
    customfield_11843,
    customfield_11841,
    customfield_11900,
    duedate,
  } = projectFields || {};

  useEffect(() => {
    const ITCAPEX = customfield_11835 ?? 0;
    const OtherCAPEX = customfield_11836 ?? 0;
    setTotalBudget(ITCAPEX + OtherCAPEX);
  }, [customfield_11835, customfield_11836]);

  return (
    <Container className="container">
      {loading ? (
        <Loading />
      ) : (
        <Wrapper>
          <div className="page-header">
            <h2>Project Detail</h2>
            <div className="flex">
              <Breadcrumb
                page="Detail"
                link={`project-details/pmo?id=${id}`}
                pmo
              />
            </div>
            <div className="link-to-jira">
              <a
                href={`https://lotusretails.atlassian.net/browse/${data.key}`}
                target="_blank"
              >
                <Button>
                  <LinkIcon />
                  View Issue on Jira
                </Button>
              </a>
            </div>
          </div>
          <div className="project-detail">
            <div className="top-section">
              <div className="percent-completed">
                <ProgressBars
                  data={{
                    label: "Percent Completed",
                    percent: customfield_11843
                      ? parseInt(customfield_11843?.value.replace("%", ""), 10)
                      : "",
                    color: "#0071cd",
                  }}
                />
              </div>

              <h2>{summary}</h2>
              <div className="flex top">
                <h2 className="flex">
                  {customfield_11758?.length > 0 && (
                    <Chip
                      className="domain"
                      color="error"
                      label={customfield_11758[0]?.value}
                      size="small"
                    />
                  )}

                  {customfield_11833 &&
                    customfield_11833?.value !== "Finance" && (
                      <Chip
                        className="domain"
                        color="error"
                        label={customfield_11833?.value}
                        size="small"
                      />
                    )}

                  {customfield_10193 && (
                    <Flags data={customfield_10193.value} pmo />
                  )}
                </h2>

                <div className="flex">
                  {customfield_11844 && (
                    <p className="risk">
                      Risk Status:{" "}
                      <span>
                        {statusChip(
                          customfield_11844?.value
                            .replace(/\(.*?\)/g, "")
                            .trim()
                        )}
                      </span>
                    </p>
                  )}

                  <div className="flex">
                    <p className="risk">
                      Project Status: {statusChip(customfield_11842?.value)}
                    </p>
                  </div>
                </div>
              </div>

              <p className="tpm">
                <UsersIcon className={classes.svg} />
                PM: {customfield_11752?.displayName || "N/A"}
                <span>
                  <UsersIcon className={classes.svg} />
                  PO: {customfield_11760?.displayName || "N/A"}
                </span>
                <span>
                  <UsersIcon className={classes.svg} />
                  QA: {customfield_11841?.displayName || "N/A"}
                </span>
              </p>
            </div>

            <div className="detail-container">
              {customfield_11785 && (
                <h3 className="released">
                  <span>
                    <RocketLaunchIcon /> <b>Release:</b>{" "}
                    {moment(customfield_11785).format("DD MMMM YYYY")}
                  </span>
                </h3>
              )}

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={7}>
                    <div className="summary-item">
                      <div className="flex">
                        <h4 className="no-border">
                          <BookOpenIcon /> Project Detail
                        </h4>
                        <div className="flex no-border type">
                          <h4 className="uppercase no-border">
                            {customfield_11834?.value || ""}
                          </h4>
                        </div>
                      </div>

                      <div className="detail-item no-border">
                        <div className="jira-description pmo">
                          <h5>Description</h5>
                          <JiraDescription
                            data={projectFields?.description?.content || []}
                          />

                          <h5>
                            Prioritization Scale (Complexity, Business Impact):{" "}
                            <span>{customfield_11837?.value || ""}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <div className="summary-item">
                      <h4>
                        <BanknotesIcon /> Budget Summary
                      </h4>
                      <div className="detail-item flex no-border">
                        <div className="item pmo ontrack">
                          <p>Budget Code</p>
                          <h3>{customfield_11838 || "N/A"}</h3>
                        </div>
                        <div className="item pmo inprogress">
                          <p>Budget - IT CAPEX (mB)</p>
                          <h3>{numberWithCommas(customfield_11835) || 0}</h3>
                        </div>
                        <div className="item pmo at-risk">
                          <p>Budget - Others CAPEX</p>
                          <h3>{numberWithCommas(customfield_11836) || 0}</h3>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Budget id={id} budget={totalBudget} />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <div className="summary-item">
                      <h4>
                        <CloudIcon /> Hosting
                      </h4>
                      <div className="detail-item no-border centered">
                        <div className="jira-description">
                          {customfield_11839?.map((i, index) => (
                            <h4>
                              {index + 1}: {i.value}
                            </h4>
                          )) || "N/A"}
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <div className="summary-item">
                      <h4>
                        <UsersIcon /> Effort Detail
                      </h4>
                      <div className="detail-item flex no-border pmo-development">
                        <div className="jira-description pmo">
                          <JiraDescription
                            data={customfield_11900?.content || []}
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <div className="summary-item">
                      <h4>
                        <CodeBracketIcon /> Develop team (team name, vendor
                        name)
                      </h4>
                      <div className="detail-item flex no-border pmo-development">
                        <div className="item pmo ontrack">
                          <p>Development</p>
                          <h3>{customfield_11840?.value || "N/A"}</h3>
                        </div>

                        <div className="item team">
                          <h5>Team(s):</h5>
                          <p>{customfield_11899 || "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className="summary-item">
                      <h4>
                        <CalendarDaysIcon /> Plan
                      </h4>
                      <div className="detail-item flex no-border">
                        <div className="item v-center border">
                          <p>Start Date</p>
                          <h3>{formatDate(customfield_10015)}</h3>
                        </div>
                        <div className="item v-center border">
                          <p>Due Date</p>
                          <h3>{formatDate(duedate)}</h3>
                        </div>
                        <div className="item v-center border">
                          <p>Actual Date</p>
                          <h3>{formatDate(customfield_11845)}</h3>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className="summary-item">
                      <h4>
                        <PencilIcon /> Note
                      </h4>
                      <div className="detail-item no-border">
                        <JiraLatestComment data={data} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
        </Wrapper>
      )}
    </Container>
  );
};

export default PMOProjectDetail;
