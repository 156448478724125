const PMO_DETAIL = {
  expand:
    "renderedFields,names,schema,operations,editmeta,changelog,versionedRepresentations",
  id: "379753",
  self: "https://lotusretails.atlassian.net/rest/api/3/issue/379753",
  key: "PMO-60",
  fields: {
    customfield_10193: {
      self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
      value: "TH",
      id: "11392",
    },
    description: {
      type: "doc",
      version: 1,
      content: [
        {
          type: "panel",
          content: [
            {
              type: "heading",
              attrs: {
                level: 3,
              },
              content: [
                {
                  type: "text",
                  text: "***Enter",
                  marks: [
                    {
                      type: "em",
                    },
                    {
                      type: "underline",
                    },
                  ],
                },
                {
                  type: "text",
                  text: " ",
                },
                {
                  type: "text",
                  text: "Business Impact",
                  marks: [
                    {
                      type: "em",
                    },
                    {
                      type: "strong",
                    },
                    {
                      type: "underline",
                    },
                  ],
                },
                {
                  type: "text",
                  text: " ",
                },
                {
                  type: "text",
                  text: "_or short description about this project",
                  marks: [
                    {
                      type: "strong",
                    },
                  ],
                },
                {
                  type: "text",
                  text: "**_*You may put N/A if there is no information at the moment of card creation.",
                },
              ],
            },
          ],
          attrs: {
            panelType: "info",
          },
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "For testing",
            },
          ],
        },
      ],
    },
    customfield_11760: null,
    customfield_11841: {
      self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=64140771407493675d46384f",
      accountId: "64140771407493675d46384f",
      emailAddress: "parama.paramagul@lotuss.com",
      avatarUrls: {
        "48x48":
          "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/64140771407493675d46384f/080855b2-cb34-4e3a-b316-1c98a3835044/48",
        "24x24":
          "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/64140771407493675d46384f/080855b2-cb34-4e3a-b316-1c98a3835044/24",
        "16x16":
          "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/64140771407493675d46384f/080855b2-cb34-4e3a-b316-1c98a3835044/16",
        "32x32":
          "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/64140771407493675d46384f/080855b2-cb34-4e3a-b316-1c98a3835044/32",
      },
      displayName: "Parama Paramagul",
      active: true,
      timeZone: "Asia/Bangkok",
      accountType: "atlassian",
    },
    customfield_11840: {
      self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16337",
      value: "Internal",
      id: "16337",
    },
    customfield_10015: "2024-09-01",
    customfield_11834: {
      self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16316",
      value: "Innovation",
      id: "16316",
    },
    customfield_11833: null,
    customfield_11899: "ECI, Automation",
    customfield_11836: 0,
    customfield_11835: 100000,
    customfield_11758: [
      {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16193",
        value: "PMO",
        id: "16193",
      },
      {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16195",
        value: "Software Dev.",
        id: "16195",
      },
    ],
    customfield_11838: 0,
    customfield_11837: {
      self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16322",
      value: "M-H",
      id: "16322",
    },
    customfield_11839: [
      {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16334",
        value: "Cloud (AWS)",
        id: "16334",
      },
      {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16336",
        value: "Cloud (TENCENT)",
        id: "16336",
      },
    ],
    summary: "PMO Automation Report Dashboard",
    subtasks: [],
    customfield_11752: null,
    customfield_11843: {
      self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16357",
      value: "90%",
      id: "16357",
    },
    customfield_11842: {
      self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
      value: "UAT",
      id: "16345",
    },
    customfield_11900: {
      type: "doc",
      version: 1,
      content: [
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "QA: 10 MD",
            },
          ],
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "Dev: 30 MD",
            },
          ],
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "PO: 10 MD",
            },
          ],
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "PM: 10 MD",
            },
          ],
        },
      ],
    },
    customfield_11845: "2024-12-28",
    customfield_11844: {
      self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
      value: "On-track (Green)",
      id: "16359",
    },
    duedate: "2024-12-19",
    comment: {
      comments: [],
      self: "https://lotusretails.atlassian.net/rest/api/3/issue/379753/comment",
      maxResults: 0,
      total: 0,
      startAt: 0,
    },
    assignee: null,
    status: {
      self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
      description: "",
      iconUrl:
        "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
      name: "Active",
      id: "11652",
      statusCategory: {
        self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
        id: 4,
        key: "indeterminate",
        colorName: "yellow",
        name: "In Progress",
      },
    },
  },
};

export default PMO_DETAIL;
