import React from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Box, Typography } from "@mui/material";

const MessageContent = ({ content, isUser }) => {
  return (
    <ReactMarkdown
      components={{
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              style={vscDarkPlus}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
        p: ({ children }) => (
          <Typography
            variant="body1"
            sx={{
              mt: 1,
              mb: 1,
              color: isUser ? "#fff" : "inherit",
              "& code": {
                backgroundColor: isUser
                  ? "rgba(255,255,255,0.1)"
                  : "rgba(0,0,0,0.1)",
                padding: "2px 4px",
                borderRadius: 1,
                fontFamily: "monospace",
              },
            }}
          >
            {children}
          </Typography>
        ),
        ul: ({ children }) => (
          <Box component="ul" sx={{ pl: 2, m: 0 }}>
            {children}
          </Box>
        ),
        li: ({ children }) => (
          <Typography
            component="li"
            sx={{
              color: isUser ? "#fff" : "inherit",
              mb: 0.5,
            }}
          >
            {children}
          </Typography>
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );
};

export default MessageContent;
