import React, { useEffect, useMemo, useState } from "react";
import { Wrapper } from "./styles";
import {
  Container,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import Breadcrumb from "../../shared/Breadcrumbs";
import Loading from "../../shared/Loading";
import DoughnutChart from "../../shared/DoughnutChart";
import StackedBarChart from "../../shared/StackedBarChart";
import Projects from "../DashboardItem/PMO/Projects";
import ThisMonthGoLive from "../DashboardItem/PMO/ThisMonthGoLive";
import HighlightsProjects from "../DashboardItem/PMO/HighlightsProjects";
import Resources from "../DashboardItem/PMO/Resources";
import ResourceByDomain from "../DashboardItem/PMO/ResourcesByDomain";
import DemandProjects from "../DashboardItem/PMO/DemandProjects";
import getJira from "../../services/getJira";
import getStatusCounts from "../../utils/statusCounts";
import { useAdminState } from "../../context/AdminContext";
import { StarIcon, BuildingOffice2Icon } from "@heroicons/react/24/outline";
import FloatingChat from "../Chatbox";
import moment from "moment";

function PMODashboard() {
  const { jira, loading } = useAdminState().jira;
  const { resources } = useAdminState().resources;
  const { login: { user: { profile } = {}, role, isLevel3and4 } = {} } =
    useAdminState();

  const [projectData, setProjectData] = useState({
    cancelled: "",
    demand: [],
    PMO: [],
    status: {},
  });

  const [startDueDate, setStartDueDate] = useState(
    moment().startOf("year").format("YYYY-MM-DD")
  );

  useEffect(() => {
    getJira(
      `(project = PMO AND duedate >= "${startDueDate}" AND duedate <= endOfYear(+1y)) OR (project = DEM AND status NOT IN ("Done", "On Hold", "Cancel", "Submit To Portfolio"))`,
      "summary, status, duedate, assignee, customfield_10193, customfield_11833, customfield_11758, customfield_11845, customfield_11842, customfield_11844, customfield_11843, customfield_10671, customfield_10651"
    );
  }, [startDueDate, jira]);

  useEffect(() => {
    if (jira.length) {
      setProjectData((prevData) => ({
        ...prevData,
        demand: jira.filter((item) => item?.key?.startsWith("DEM-")),
        PMO: jira.filter((item) => item?.key?.startsWith("PMO-")),
      }));
    }
  }, [jira]);

  const chartData = useMemo(() => {
    const statusCounts = getStatusCounts(jira, "status");
    const transformed = {
      "In Progress":
        (statusCounts["Active"] || 0) +
        (statusCounts["ACTIVE (Project Highlight)"] || 0) +
        (statusCounts["Open from DM (temp.)"] || 0),
      Completed: statusCounts["Completed"] || 0,
      "On Hold": statusCounts["On Hold"] || 0,
    };

    setProjectData((prevData) => ({
      ...prevData,
      status: transformed,
      cancelled: statusCounts["Cancelled"] || 0,
    }));

    return {
      labels: Object.keys(transformed),
      datasets: [
        {
          data: Object.values(transformed),
          backgroundColor: [
            "rgba(254, 200, 77, 0.8)",
            "rgba(0, 177, 176, 0.5)",
            "rgba(253, 126, 64, 0.8)",
          ],
        },
      ],
    };
  }, [jira]);

  const byDomainData = useMemo(() => {
    const transformed = projectData.PMO.reduce((acc, item) => {
      const category = item?.fields?.customfield_11758?.[0]?.value;
      const riskStatus = item?.fields?.customfield_11844?.value;
      if (!category || !riskStatus) return acc;
      if (!acc[category]) acc[category] = {};
      if (!acc[category][riskStatus]) acc[category][riskStatus] = 0;

      acc[category][riskStatus] += 1;

      return acc;
    }, {});

    const categories = Object.keys(transformed);
    const riskStatuses = Array.from(
      new Set(Object.values(transformed).flatMap((obj) => Object.keys(obj)))
    );

    return {
      labels: categories,
      datasets: riskStatuses.map((status) => ({
        label: status,
        data: categories.map((category) => transformed[category][status] || 0),
        backgroundColor: status.includes("Green")
          ? "rgba(0, 177, 176, 0.5)"
          : status.includes("Yellow")
          ? "rgba(254, 200, 77, 0.8)"
          : "rgba(253, 126, 64, 0.8)",
      })),
    };
  }, [projectData.PMO]);

  const memProjects = useMemo(
    () => <Projects project={projectData.PMO} />,
    [projectData.PMO]
  );
  const memHighlights = useMemo(
    () => <HighlightsProjects project={projectData.PMO} />,
    [projectData.PMO]
  );
  const memGoLive = useMemo(
    () => <ThisMonthGoLive project={projectData.PMO} />,
    [projectData.PMO]
  );
  const memDemand = useMemo(
    () => <DemandProjects demand={projectData.demand} />,
    [projectData.demand]
  );

  const handleDueDateChange = (event) => {
    setStartDueDate(
      moment().year(event.target.value).startOf("year").format("YYYY-MM-DD")
    );
  };

  return (
    <Wrapper>
      <Container className="container">
        <div className="page-header">
          <h2>PMO Dashboard</h2>
          <div className="flex">
            <Breadcrumb page="PMO Dashboard" link="#" />
          </div>
          <p className="welcome">
            Hi, <span>{profile?.given_name || ""}</span>. Welcome back to
            Project Status Automation report!
          </p>
          <div className="dashboard-year flex">
            <p>Report Year:</p>
            <FormControl
              className="dropdown"
              variant="outlined"
              margin="normal"
              size="small"
            >
              <Select
                value={moment(startDueDate).year()}
                onChange={handleDueDateChange}
              >
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            <div className="dashboard-wrapper team-dashboard">
              {process.env.GATSBY_APP_ENV === "development" && (
                <FloatingChat position="right" data={jira} />
              )}
              <Grid container spacing={4}>
                <Grid item xs={12} md={5}>
                  <div className="summary-item project-sum">
                    <div className="flex">
                      <h4 className="no-border">
                        <StarIcon />
                        Project Summary (number of projects)
                      </h4>
                    </div>
                    <div className="detail-item flex no-border">
                      <div className="pie-chart-container total-projects">
                        <div className="total">
                          <h2>Total</h2>
                          <p>{projectData.PMO.length}</p>
                        </div>

                        {chartData ? (
                          <DoughnutChart data={chartData} summary />
                        ) : (
                          <p>No data available</p>
                        )}

                        <div className="summary-status">
                          <ul>
                            {Object.entries(projectData.status).map(
                              ([key, count], index) => (
                                <li key={key} className={`dot-${index + 1}`}>
                                  {key}
                                </li>
                              )
                            )}
                            <li className="cancelled">
                              Cancelled: <span>{projectData.cancelled}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={7}>
                  <div className="summary-item project-sum">
                    <div className="flex">
                      <h4 className="no-border">
                        <BuildingOffice2Icon />
                        Project By Domain
                      </h4>
                    </div>
                    <div className="detail-item flex no-border">
                      <div className="number-of-project"># of projects</div>
                      {byDomainData ? (
                        <StackedBarChart data={byDomainData} />
                      ) : (
                        <p>No data available</p>
                      )}
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  {memProjects}
                </Grid>

                <Grid item xs={12}>
                  {memHighlights}
                </Grid>

                <Grid item xs={12}>
                  {memGoLive}
                </Grid>

                <Grid item xs={12} md={12}>
                  {memDemand}
                </Grid>

                {(role === "SuperAdmin" ||
                  role === "Admin" ||
                  isLevel3and4) && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Resources />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <ResourceByDomain resources={resources || []} />
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          </Box>
        )}
      </Container>
    </Wrapper>
  );
}

export default PMODashboard;
