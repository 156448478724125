const rolesToCheck = new Map([
  ["GG-TH-PMODashabord-SuperAdmin", "SuperAdmin"],
  ["GG-TH-PMODashabord-Admin", "Admin"],
  ["GG-TH-PMODashabord-ManagerUser", "Manager"],
]);

export const getRoleType = (userRoles) => {
  if (!Array.isArray(userRoles) || userRoles.length === 0) {
    return null;
  }

  const matchedRole = userRoles.find((role) => rolesToCheck.has(role));
  return matchedRole ? rolesToCheck.get(matchedRole) : null;
};

export const getWorkLevel = (userRoles) => {
  if (!Array.isArray(userRoles) || userRoles.length === 0) {
    return null;
  }

  const workLevelPattern = /WorkLevel(\d+)/;
  const matchedWorkLevel = userRoles.find((role) => {
    return workLevelPattern.test(role);
  });

  if (matchedWorkLevel) {
    const workLevelMatch = matchedWorkLevel.match(workLevelPattern);
    return workLevelMatch ? parseInt(workLevelMatch[1], 10) : null;
  }

  return null;
};

export const isPermissionAllowed = (userRoles) => {
  const workLevel = getWorkLevel(userRoles);
  return workLevel !== null && workLevel > 2;
};
