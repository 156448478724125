const PMO_DATA = [
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379904",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379904",
    key: "PMO-208",
    fields: {
      summary: "Data Report Enhancement",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16185",
          value: "Data Analytics",
          id: "16185",
        },
      ],
      duedate: "2024-03-15",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379892",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379892",
    key: "PMO-196",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      summary: "Mall Data Mart CY2023",
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16309",
        value: "Mall",
        id: "16309",
      },
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      duedate: "2024-11-04",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379880",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379880",
    key: "PMO-184",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16350",
        value: "20%",
        id: "16350",
      },
      summary: "HR: Workforce management (WFM) - Phase2",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16340",
        value: "Initial",
        id: "16340",
      },
      customfield_11845: null,
      customfield_11844: null,
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16307",
        value: "People",
        id: "16307",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-04-30",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10013",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Cancelled",
        id: "10013",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379877",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379877",
    key: "PMO-181",
    fields: {
      summary: "HR : HR Database – Insight Phase 2",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16357",
        value: "90%",
        id: "16357",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16307",
        value: "People",
        id: "16307",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-12-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379873",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379873",
    key: "PMO-177",
    fields: {
      summary: "DM-106 Deposit refund at HO (AUDIT)",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16309",
        value: "Mall",
        id: "16309",
      },
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      duedate: "2024-05-16",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379872",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379872",
    key: "PMO-176",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16348",
        value: "0%",
        id: "16348",
      },
      summary: "DM-141 WH Tax agent​",
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: null,
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16309",
        value: "Mall",
        id: "16309",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-12-30",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/1",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/open.png",
        name: "Open",
        id: "1",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379868",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379868",
    key: "PMO-172",
    fields: {
      customfield_11843: null,
      summary: "DM-132 Lotus's Booking​",
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: null,
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16309",
        value: "Mall",
        id: "16309",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      duedate: "2024-03-31",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10011",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "On Hold",
        id: "10011",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379861",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379861",
    key: "PMO-165",
    fields: {
      summary: "Finance: DM-115 Bidding workflow",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16349",
        value: "10%",
        id: "16349",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: null,
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16311",
        value: "Finance",
        id: "16311",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      duedate: "2024-03-31",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10011",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "On Hold",
        id: "10011",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379860",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379860",
    key: "PMO-164",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16349",
        value: "10%",
        id: "16349",
      },
      summary: "Finance: Enhance PO spend report  (ERP & Power BI)",
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: null,
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16311",
        value: "Finance",
        id: "16311",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      duedate: "2024-03-31",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10011",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "On Hold",
        id: "10011",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379858",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379858",
    key: "PMO-162",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16351",
        value: "30%",
        id: "16351",
      },
      summary: "Finance: Makro Snerygy - Makro EPM",
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: null,
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16311",
        value: "Finance",
        id: "16311",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      duedate: "2024-03-31",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10011",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "On Hold",
        id: "10011",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379855",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379855",
    key: "PMO-159",
    fields: {
      summary: "New North DC (Business Budget)",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16188",
          value: "Fulfillment",
          id: "16188",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-07-08",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379854",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379854",
    key: "PMO-158",
    fields: {
      summary: "VmWare Revolution (TH) 2024",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16354",
        value: "60%",
        id: "16354",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16342",
        value: "Requirement",
        id: "16342",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      duedate: "2026-12-31",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379853",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379853",
    key: "PMO-157",
    fields: {
      summary: "(EOL) Infra - DC - Network CY2023",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16341",
        value: "Planning",
        id: "16341",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-01-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379852",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379852",
    key: "PMO-156",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16355",
        value: "70%",
        id: "16355",
      },
      summary: "CPFM Transition System to SIM & R10 - Pilot Store",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16360",
        value: "Off-track (Yellow)",
        id: "16360",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16190",
          value: "New Business",
          id: "16190",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2025-06-30",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379850",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379850",
    key: "PMO-154",
    fields: {
      summary: "Project B2C Vending Machine Rollout Phase I",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16347",
        value: "Rollout",
        id: "16347",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16190",
          value: "New Business",
          id: "16190",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-02-21",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379848",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379848",
    key: "PMO-152",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      summary: "IT Ticketing",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16347",
        value: "Rollout",
        id: "16347",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16193",
          value: "PMO",
          id: "16193",
        },
      ],
      duedate: "2024-03-30",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379847",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379847",
    key: "PMO-151",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      summary: "Customer System Optimization - Mulesoft redesign CY2023",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16347",
        value: "Rollout",
        id: "16347",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: null,
      duedate: "2024-11-05",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379846",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379846",
    key: "PMO-150",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16354",
        value: "60%",
        id: "16354",
      },
      summary:
        "DM125_New Store DB Engine Replacement - Couchbase (via R10) for CMA Server (Couchbase Replacement) (via R10)",
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16360",
        value: "Off-track (Yellow)",
        id: "16360",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16196",
          value: "Store",
          id: "16196",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-03-28",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10011",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "On Hold",
        id: "10011",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379845",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379845",
    key: "PMO-149",
    fields: {
      summary: "Separation Program - Buy and Merchandise (Oracle SIM)",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16356",
        value: "80%",
        id: "16356",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16196",
          value: "Store",
          id: "16196",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2025-03-01",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11653",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active Project Highlight",
        id: "11653",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379844",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379844",
    key: "PMO-148",
    fields: {
      summary: "Separation Program - Store Systems (R10)",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16196",
          value: "Store",
          id: "16196",
        },
      ],
      duedate: "2024-09-13",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379841",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379841",
    key: "PMO-145",
    fields: {
      summary: "DM085-Product and Price Enhancement (via R10)",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16194",
          value: "Product",
          id: "16194",
        },
      ],
      duedate: "2024-08-30",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379835",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379835",
    key: "PMO-139",
    fields: {
      summary: "DM057 CN Allocation by Items",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16354",
        value: "60%",
        id: "16354",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16194",
          value: "Product",
          id: "16194",
        },
      ],
      duedate: "2025-04-30",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379834",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379834",
    key: "PMO-138",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16355",
        value: "70%",
        id: "16355",
      },
      summary: "Data quality platform CY2023",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16194",
          value: "Product",
          id: "16194",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-04-30",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10011",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "On Hold",
        id: "10011",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379832",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379832",
    key: "PMO-136",
    fields: {
      summary: "Project Ex - Gas Station Sale API Interface (BCP)",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16355",
        value: "70%",
        id: "16355",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16343",
        value: "Analysis&Design",
        id: "16343",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16190",
          value: "New Business",
          id: "16190",
        },
      ],
      duedate: "2025-02-15",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379831",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379831",
    key: "PMO-135",
    fields: {
      summary: "Data Customer Reporting-Roadmap CY2023",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16353",
        value: "50%",
        id: "16353",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16185",
          value: "Data Analytics",
          id: "16185",
        },
      ],
      duedate: "2024-02-29",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379826",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379826",
    key: "PMO-130",
    fields: {
      summary: "(EOL) Infra - HO - Network CY2023",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-01-20",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379825",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379825",
    key: "PMO-129",
    fields: {
      summary: "(EOL) Infra - Store - Network CY2023",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-01-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379824",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379824",
    key: "PMO-128",
    fields: {
      summary: "LDD/SoA (via R10)",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16357",
        value: "90%",
        id: "16357",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: null,
      duedate: "2024-09-20",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379823",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379823",
    key: "PMO-127",
    fields: {
      summary: "HR : Digitalized Talent Acquisition",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16307",
        value: "People",
        id: "16307",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-06-21",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379821",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379821",
    key: "PMO-125",
    fields: {
      summary: "DM-142 Store Cash Office Fraud Prevention​ (Ph2)",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16356",
        value: "80%",
        id: "16356",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16360",
        value: "Off-track (Yellow)",
        id: "16360",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16311",
        value: "Finance",
        id: "16311",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-08-19",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10011",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "On Hold",
        id: "10011",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379813",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379813",
    key: "PMO-117",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16349",
        value: "10%",
        id: "16349",
      },
      summary: "Stabilization - Systems Monitoring",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16197",
          value: "Application Support",
          id: "16197",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-01-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10013",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Cancelled",
        id: "10013",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379812",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379812",
    key: "PMO-116",
    fields: {
      summary: "Stabilization - Data Reconciliation",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16349",
        value: "10%",
        id: "16349",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16197",
          value: "Application Support",
          id: "16197",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-01-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10013",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Cancelled",
        id: "10013",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379809",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379809",
    key: "PMO-113",
    fields: {
      summary: "Bakery Automate Daily Production Plan - CY2023",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16351",
        value: "30%",
        id: "16351",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16196",
          value: "Store",
          id: "16196",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-01-24",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379807",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379807",
    key: "PMO-111",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16357",
        value: "90%",
        id: "16357",
      },
      summary: "DM97 -DTS Shop in Shop (IT Budget)",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16340",
        value: "Initial",
        id: "16340",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16190",
          value: "New Business",
          id: "16190",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-01-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379803",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379803",
    key: "PMO-107",
    fields: {
      summary: "DM-133 Lotus's Eat",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16309",
        value: "Mall",
        id: "16309",
      },
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-05-07",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379802",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379802",
    key: "PMO-106",
    fields: {
      summary: "DM-144 O2O Roadmap 2023",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16340",
        value: "Initial",
        id: "16340",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16192",
          value: "O2O",
          id: "16192",
        },
      ],
      duedate: "2024-02-29",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379801",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379801",
    key: "PMO-105",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16348",
        value: "0%",
        id: "16348",
      },
      summary: "DM 149 DM149 Move DcKPI to Lotuss domain (WH Relocation)",
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16305",
        value: "Store and DC",
        id: "16305",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16188",
          value: "Fulfillment",
          id: "16188",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-06-08",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10011",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "On Hold",
        id: "10011",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379800",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379800",
    key: "PMO-104",
    fields: {
      summary: "DM-122 FM Maintenance",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16310",
        value: "Property",
        id: "16310",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      duedate: "2024-11-25",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14588",
        value: "TH/MY",
        id: "14588",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379798",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379798",
    key: "PMO-102",
    fields: {
      summary: "Amaze SuperApp(OneID) : Phase1",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16347",
        value: "Rollout",
        id: "16347",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16184",
          value: "Customer",
          id: "16184",
        },
      ],
      duedate: "2024-04-04",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379797",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379797",
    key: "PMO-101",
    fields: {
      summary: "HR : WFM Project Rollout",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16355",
        value: "70%",
        id: "16355",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16307",
        value: "People",
        id: "16307",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      duedate: "2025-02-03",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379796",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379796",
    key: "PMO-100",
    fields: {
      summary: "Non-Thai registration",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16184",
          value: "Customer",
          id: "16184",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-07-16",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379795",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379795",
    key: "PMO-99",
    fields: {
      summary: "Coin rescheme",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16347",
        value: "Rollout",
        id: "16347",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16184",
          value: "Customer",
          id: "16184",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-02-01",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379794",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379794",
    key: "PMO-98",
    fields: {
      summary: "Speed up coin notification",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16347",
        value: "Rollout",
        id: "16347",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16184",
          value: "Customer",
          id: "16184",
        },
      ],
      duedate: "2024-03-31",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379793",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379793",
    key: "PMO-97",
    fields: {
      summary: "Requrest_Upgrade Cortex XDR Agents 8.2.1",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16351",
        value: "30%",
        id: "16351",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16288",
        value: "IT Security",
        id: "16288",
      },
      customfield_11758: null,
      duedate: "2024-02-15",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10013",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Cancelled",
        id: "10013",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379792",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379792",
    key: "PMO-96",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16351",
        value: "30%",
        id: "16351",
      },
      summary: "Setting DLP for confidential information on 3 persons",
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16361",
        value: "At Risk (Red)",
        id: "16361",
      },
      customfield_11758: null,
      duedate: "2024-02-19",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10013",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Cancelled",
        id: "10013",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379791",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379791",
    key: "PMO-95",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      summary: "Customers : Referral Code",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16184",
          value: "Customer",
          id: "16184",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-07-03",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379790",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379790",
    key: "PMO-94",
    fields: {
      summary:
        "DM-122 Delay on SOW sign of, pending AXONS target by end of Mar 24",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16350",
        value: "20%",
        id: "16350",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16310",
        value: "Property",
        id: "16310",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-03-29",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10013",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Cancelled",
        id: "10013",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379788",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379788",
    key: "PMO-92",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      summary: "Mall Tenant Portal",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: null,
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16309",
        value: "Mall",
        id: "16309",
      },
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      duedate: "2024-09-02",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379787",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379787",
    key: "PMO-91",
    fields: {
      summary: "Store Specific Range & Store POG CT2023",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16357",
        value: "90%",
        id: "16357",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: null,
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16301",
        value: "SRD",
        id: "16301",
      },
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16360",
        value: "Off-track (Yellow)",
        id: "16360",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16194",
          value: "Product",
          id: "16194",
        },
      ],
      duedate: "2025-03-17",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11653",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active Project Highlight",
        id: "11653",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379786",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379786",
    key: "PMO-90",
    fields: {
      summary: "Coin Rescheme",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16184",
          value: "Customer",
          id: "16184",
        },
      ],
      duedate: "2024-06-28",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379785",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379785",
    key: "PMO-89",
    fields: {
      summary: "Price Change and Promotion Zone Group 2 (Regional Price)",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16347",
        value: "Rollout",
        id: "16347",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16194",
          value: "Product",
          id: "16194",
        },
      ],
      duedate: "2024-06-06",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379784",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379784",
    key: "PMO-88",
    fields: {
      summary: "MFC - Hyndai x O2O x Core IT",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16354",
        value: "60%",
        id: "16354",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16192",
          value: "O2O",
          id: "16192",
        },
      ],
      duedate: "2025-04-30",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379783",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379783",
    key: "PMO-87",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      summary: "Share Drive Migration",
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-12-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379782",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379782",
    key: "PMO-86",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16354",
        value: "60%",
        id: "16354",
      },
      summary: "Operating System End Of Support Redhat",
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16303",
        value: "Server Project",
        id: "16303",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2025-01-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379781",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379781",
    key: "PMO-85",
    fields: {
      summary: "(Linux)Operating System End Of Support AIX",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16303",
        value: "Server Project",
        id: "16303",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-11-30",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379780",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379780",
    key: "PMO-84",
    fields: {
      summary: "(Linux)New North DC project  (Unix - Server)",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16296",
        value: "W/H",
        id: "16296",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      duedate: "2024-08-31",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379779",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379779",
    key: "PMO-83",
    fields: {
      summary: "vRA and Enhancement Automation Management",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16303",
        value: "Server Project",
        id: "16303",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-11-30",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379778",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379778",
    key: "PMO-82",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16348",
        value: "0%",
        id: "16348",
      },
      summary: "CPAxtra Synergy (E-Mail)",
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16303",
        value: "Server Project",
        id: "16303",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-12-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10011",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "On Hold",
        id: "10011",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379777",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379777",
    key: "PMO-81",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16349",
        value: "10%",
        id: "16349",
      },
      summary: "TANZU Implement",
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16303",
        value: "Server Project",
        id: "16303",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-12-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379776",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379776",
    key: "PMO-80",
    fields: {
      summary: "Slab Pricing (Promotion)",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16194",
          value: "Product",
          id: "16194",
        },
      ],
      duedate: "2024-09-24",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379775",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379775",
    key: "PMO-79",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16355",
        value: "70%",
        id: "16355",
      },
      summary: "Supply Chain Enhancement Y2024",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16188",
          value: "Fulfillment",
          id: "16188",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-12-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379774",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379774",
    key: "PMO-78",
    fields: {
      summary: "Project : CMA - Order Review",
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-07-05",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379773",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379773",
    key: "PMO-77",
    fields: {
      summary: "MILO (Migration & Relocation)",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16349",
        value: "10%",
        id: "16349",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2025-12-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10011",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "On Hold",
        id: "10011",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379772",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379772",
    key: "PMO-76",
    fields: {
      summary: "Lotus Tag : Enhancement to auto recheck tag and tag detection",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16347",
        value: "Rollout",
        id: "16347",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-04-30",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379771",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379771",
    key: "PMO-75",
    fields: {
      summary: "sFTP Server : To migrate from VM to Container.",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16355",
        value: "70%",
        id: "16355",
      },
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      duedate: "2024-08-31",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10011",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "On Hold",
        id: "10011",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379770",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379770",
    key: "PMO-74",
    fields: {
      summary: "Makro Staff Coin",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16354",
        value: "60%",
        id: "16354",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16311",
        value: "Finance",
        id: "16311",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-10-01",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379769",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379769",
    key: "PMO-73",
    fields: {
      summary: "MTO (Multi-Tenant Organization)",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16189",
          value: "Infrastructure",
          id: "16189",
        },
      ],
      duedate: "2024-08-31",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379768",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379768",
    key: "PMO-72",
    fields: {
      summary: "KTL - credit score",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16356",
        value: "80%",
        id: "16356",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16184",
          value: "Customer",
          id: "16184",
        },
      ],
      duedate: "2025-03-18",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379767",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379767",
    key: "PMO-71",
    fields: {
      summary: "tenants portal - pocurement",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16355",
        value: "70%",
        id: "16355",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16306",
        value: "Legal",
        id: "16306",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2025-01-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379766",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379766",
    key: "PMO-70",
    fields: {
      summary: "System Monitoring",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16197",
          value: "Application Support",
          id: "16197",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-11-30",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379765",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379765",
    key: "PMO-69",
    fields: {
      summary: "ORWMS v12 Porting from AIX to Linux",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16343",
        value: "Analysis&Design",
        id: "16343",
      },
      customfield_11845: "2024-12-24",
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16188",
          value: "Fulfillment",
          id: "16188",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-12-24",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379764",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379764",
    key: "PMO-68",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16356",
        value: "80%",
        id: "16356",
      },
      summary: "[DC] Sort by Sequence",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16188",
          value: "Fulfillment",
          id: "16188",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2025-01-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379763",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379763",
    key: "PMO-67",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      summary: "ORWMS enhancement : Halal fresh item segregation alert",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16340",
        value: "Initial",
        id: "16340",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16188",
          value: "Fulfillment",
          id: "16188",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2024-10-16",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379762",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379762",
    key: "PMO-66",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16353",
        value: "50%",
        id: "16353",
      },
      summary: "Slab pricing phase2",
      customfield_11842: null,
      customfield_11845: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16300",
        value: "Price / Promo",
        id: "16300",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16194",
          value: "Product",
          id: "16194",
        },
      ],
      duedate: "2025-04-25",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379761",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379761",
    key: "PMO-65",
    fields: {
      summary: "Amaze SuperApp(OneID) : Phase2",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16358",
        value: "100%",
        id: "16358",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16184",
          value: "Customer",
          id: "16184",
        },
      ],
      duedate: "2024-11-08",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10069",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Completed",
        id: "10069",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379760",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379760",
    key: "PMO-64",
    fields: {
      summary: "Lotus's Connect rebranding to CP Axtra Connect",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16355",
        value: "70%",
        id: "16355",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16346",
        value: "Deployment",
        id: "16346",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16193",
          value: "PMO",
          id: "16193",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2025-03-31",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379759",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379759",
    key: "PMO-63",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16354",
        value: "60%",
        id: "16354",
      },
      summary: "Mall Tenant Portal Ph.2",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16309",
        value: "Mall",
        id: "16309",
      },
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16198",
          value: "Finance",
          id: "16198",
        },
      ],
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      duedate: "2025-03-03",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379758",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379758",
    key: "PMO-62",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16349",
        value: "10%",
        id: "16349",
      },
      summary: "New TMS Replacement",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16343",
        value: "Analysis&Design",
        id: "16343",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16188",
          value: "Fulfillment",
          id: "16188",
        },
      ],
      duedate: "2026-04-30",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14588",
        value: "TH/MY",
        id: "14588",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379757",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379757",
    key: "PMO-61",
    fields: {
      summary: "New sorter logic at WNDC914",
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16350",
        value: "20%",
        id: "16350",
      },
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16344",
        value: "Development",
        id: "16344",
      },
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16188",
          value: "Fulfillment",
          id: "16188",
        },
      ],
      duedate: "2025-02-04",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379753",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379753",
    key: "PMO-60",
    fields: {
      customfield_11843: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16357",
        value: "90%",
        id: "16357",
      },
      summary: "PMO Automation Report Dashboard",
      customfield_11842: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16345",
        value: "UAT",
        id: "16345",
      },
      customfield_11845: "2024-12-28",
      customfield_11833: null,
      customfield_11844: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16359",
        value: "On-track (Green)",
        id: "16359",
      },
      customfield_11758: [
        {
          self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16193",
          value: "PMO",
          id: "16193",
        },
      ],
      duedate: "2024-12-19",
      customfield_10193: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/11392",
        value: "TH",
        id: "11392",
      },
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11652",
        description: "",
        iconUrl:
          "https://lotusretails.atlassian.net/images/icons/statuses/generic.png",
        name: "Active",
        id: "11652",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379729",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379729",
    key: "DEM-299",
    fields: {
      summary: "SC Stabilization/Enhancement 2025 Phase 1",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14902",
        value: "Fulfillment",
        id: "14902",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10321",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Gate 1 -",
        id: "10321",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "379538",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/379538",
    key: "DEM-298",
    fields: {
      summary:
        "Lotus's Chatbot - Transform Rule-Based to Generative AI (Phase 3)",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14903",
        value: "Infrastructure",
        id: "14903",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A9a8d244c-3b4e-48dc-adfa-82013be52ed7",
        accountId: "712020:9a8d244c-3b4e-48dc-adfa-82013be52ed7",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/31e6bafd454eb749e0beaddbc35c0557?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGR-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/31e6bafd454eb749e0beaddbc35c0557?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGR-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/31e6bafd454eb749e0beaddbc35c0557?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGR-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/31e6bafd454eb749e0beaddbc35c0557?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGR-1.png",
        },
        displayName: "Gorrakot Rattananinad",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10320",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open",
        id: "10320",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "377160",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/377160",
    key: "DEM-297",
    fields: {
      summary: "TH - Equipment Control Application for Lotus & Makro",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14902",
        value: "Fulfillment",
        id: "14902",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6224ea65932f0f007169024a",
        accountId: "6224ea65932f0f007169024a",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/795ad55db472e660458a9dcc8fac8b09?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWS-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/795ad55db472e660458a9dcc8fac8b09?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWS-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/795ad55db472e660458a9dcc8fac8b09?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWS-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/795ad55db472e660458a9dcc8fac8b09?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWS-2.png",
        },
        displayName: "wasuthep sarawan",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10321",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Gate 1 -",
        id: "10321",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "376869",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/376869",
    key: "DEM-296",
    fields: {
      summary: "iHiring phase2",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16046",
        value: "People",
        id: "16046",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10321",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Gate 1 -",
        id: "10321",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "376606",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/376606",
    key: "DEM-295",
    fields: {
      summary: "Establish a Robust Test Environment",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14910",
        value: "Store",
        id: "14910",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=616d48cb2097220071948303",
        accountId: "616d48cb2097220071948303",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/8f636bb84dca2025bd4b1501b0815fbf?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FOS-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/8f636bb84dca2025bd4b1501b0815fbf?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FOS-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/8f636bb84dca2025bd4b1501b0815fbf?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FOS-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/8f636bb84dca2025bd4b1501b0815fbf?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FOS-6.png",
        },
        displayName: "Onica Srivicha",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "376343",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/376343",
    key: "DEM-294",
    fields: {
      summary: "TH - Automatic CN compensation collection",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14908",
        value: "Product",
        id: "14908",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "376075",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/376075",
    key: "DEM-293",
    fields: {
      summary: "TH - To Be Member Promotion",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14908",
        value: "Product",
        id: "14908",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10320",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open",
        id: "10320",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "371077",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/371077",
    key: "DEM-292",
    fields: {
      summary: "Sunshine Project",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14902",
        value: "Fulfillment",
        id: "14902",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "371042",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/371042",
    key: "DEM-291",
    fields: {
      summary: "TH - 1P Promotion Rating",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14908",
        value: "Product",
        id: "14908",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62b0105f5479e75d631ac17c",
        accountId: "62b0105f5479e75d631ac17c",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/ad425cd4df2a129273489e053ed95829?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTM-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/ad425cd4df2a129273489e053ed95829?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTM-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/ad425cd4df2a129273489e053ed95829?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTM-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/ad425cd4df2a129273489e053ed95829?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTM-2.png",
        },
        displayName: "Teerapong Meesookh",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "371033",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/371033",
    key: "DEM-290",
    fields: {
      summary: "TH - Lotus Slab Pricing (Promotion) phase2&3",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14908",
        value: "Product",
        id: "14908",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6195ab41d2e64c0071ad8af8",
        accountId: "6195ab41d2e64c0071ad8af8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
        },
        displayName: "sunisa",
        active: true,
        timeZone: "Asia/Vientiane",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "371030",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/371030",
    key: "DEM-289",
    fields: {
      summary: "TH - HLE Catalog",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14908",
        value: "Product",
        id: "14908",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6195ab41d2e64c0071ad8af8",
        accountId: "6195ab41d2e64c0071ad8af8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
        },
        displayName: "sunisa",
        active: true,
        timeZone: "Asia/Vientiane",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "369568",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/369568",
    key: "DEM-288",
    fields: {
      summary: "[Mall] Eats archidect redesign",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16047",
        value: "Mall",
        id: "16047",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "368997",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/368997",
    key: "DEM-287",
    fields: {
      summary: "DEM-287: TH SIM Performance Evaluation",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14910",
        value: "Store",
        id: "14910",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "368847",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/368847",
    key: "DEM-286",
    fields: {
      summary: "Manage PC Clock in/out",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16046",
        value: "People",
        id: "16046",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61f1fdbde218f0006af7eef9",
        accountId: "61f1fdbde218f0006af7eef9",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
        },
        displayName: "tavikarn.paisoonsin",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "368719",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/368719",
    key: "DEM-285",
    fields: {
      summary:
        "New request for New CI Deal and New 3 MDs  to support for CPAxtra ( Harmony )",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14908",
        value: "Product",
        id: "14908",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: "2025-02-28",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61fb8a7c845d670071f3b044",
        accountId: "61fb8a7c845d670071f3b044",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/b8c89a54946a6a15114ad08a411044f8?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNC-5.png",
          "24x24":
            "https://secure.gravatar.com/avatar/b8c89a54946a6a15114ad08a411044f8?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNC-5.png",
          "16x16":
            "https://secure.gravatar.com/avatar/b8c89a54946a6a15114ad08a411044f8?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNC-5.png",
          "32x32":
            "https://secure.gravatar.com/avatar/b8c89a54946a6a15114ad08a411044f8?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNC-5.png",
        },
        displayName: "Nachanok Chumpengphun",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "368608",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/368608",
    key: "DEM-284",
    fields: {
      summary: "Lotus Slab Pricing (Promotion)",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14908",
        value: "Product",
        id: "14908",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "367719",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/367719",
    key: "DEM-283",
    fields: {
      summary: "Makro and Lotus's staff coin phase 1.5",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14897",
        value: "Customer",
        id: "14897",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=63a3fc94347fae53468246e3",
        accountId: "63a3fc94347fae53468246e3",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/ded1704768e8699069ccb0aa9a4ee929?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FM-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/ded1704768e8699069ccb0aa9a4ee929?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FM-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/ded1704768e8699069ccb0aa9a4ee929?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FM-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/ded1704768e8699069ccb0aa9a4ee929?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FM-6.png",
        },
        displayName: "Maytee.ruksakiat",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "367176",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/367176",
    key: "DEM-282",
    fields: {
      summary: "BS-TB Reconcile Approval Workflow",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16045",
        value: "Finance",
        id: "16045",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ac06ddc4e-e0c1-4733-864d-f3492889061f",
        accountId: "712020:c06ddc4e-e0c1-4733-864d-f3492889061f",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/1658f7624d0028d52376c931624d7272?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNW-5.png",
          "24x24":
            "https://secure.gravatar.com/avatar/1658f7624d0028d52376c931624d7272?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNW-5.png",
          "16x16":
            "https://secure.gravatar.com/avatar/1658f7624d0028d52376c931624d7272?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNW-5.png",
          "32x32":
            "https://secure.gravatar.com/avatar/1658f7624d0028d52376c931624d7272?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNW-5.png",
        },
        displayName: "Natdanai Worasetthakul",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "367144",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/367144",
    key: "DEM-281",
    fields: {
      summary: "New sorter logic at WNDC914",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14902",
        value: "Fulfillment",
        id: "14902",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ad5159293-e479-4d50-9b19-f7f354fe2f74",
        accountId: "712020:d5159293-e479-4d50-9b19-f7f354fe2f74",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
        },
        displayName: "Sarawut Thitinanthawornsuk",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "366716",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/366716",
    key: "DEM-280",
    fields: {
      summary: "iLearn - Makro Phase 1",
      customfield_10193: null,
      customfield_10651: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16046",
        value: "People",
        id: "16046",
      },
      customfield_11843: null,
      customfield_11842: null,
      customfield_11845: null,
      customfield_11833: null,
      customfield_11844: null,
      customfield_11758: null,
      duedate: null,
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61f1fdbde218f0006af7eef9",
        accountId: "61f1fdbde218f0006af7eef9",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
        },
        displayName: "tavikarn.paisoonsin",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10320",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open",
        id: "10320",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
];

export default PMO_DATA;
