import React, { useState, useEffect, useCallback } from "react";
import { Box, Paper, Tooltip, IconButton } from "@mui/material";
import {
  BanknotesIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { getProjectBudget } from "../../../httpRequests/jira";
import Loading from "../../../shared/Loading";
import Slider from "react-slick";
import numberWithCommas from "../../../utils/numberWithCommas";
import { formatDate } from "../../../utils/checkDate";
import JiraDescription from "../shared/JiraDescription";

const Budget = ({ id, budget }) => {
  const [budgetData, setBudgetData] = useState([]);
  const [totalBudget, setTotalBudget] = useState(0);
  const [loading, setLoading] = useState(false);
  const [availableBudget, setAvailableBudget] = useState(0);

  const fetchBudgetData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getProjectBudget(
        id,
        "summary, issuetype, customfield_11904, customfield_11903, customfield_11905, description"
      );

      if (response) {
        const data = response.data || {};
        setBudgetData(data.issues);
      } else {
        console.error("No response received for budget data");
      }
    } catch (error) {
      console.error("Error fetching budget data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchBudgetData();
  }, [fetchBudgetData]);

  useEffect(() => {
    const total = budgetData.reduce(
      (sum, { fields: { customfield_11904 } }) =>
        sum + (typeof customfield_11904 === "number" ? customfield_11904 : 0),
      0
    );
    setTotalBudget(total);
    setAvailableBudget(budget - total);
  }, [budgetData]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return loading ? (
    <Loading />
  ) : (
    <div className="summary-item">
      <div className="flex">
        <h4 className="no-border">
          <BanknotesIcon />
          Budget Detail
        </h4>

        <div
          className={`flex no-border type ${
            availableBudget <= 0 ? "over-budget" : ""
          }`}
        >
          <h4 className="uppercase no-border budget-spending">
            Spending: {numberWithCommas(totalBudget) || 0} (THB)
            <br />
            <span>Available: {numberWithCommas(availableBudget)} (THB)</span>
          </h4>
        </div>
      </div>

      {budgetData?.length === 0 ? (
        <div className="detail-item flex no-border centered">
          <h3>No Detail</h3>
        </div>
      ) : (
        <div className="detail-item no-border">
          <div className="budget-detail-container">
            <div className="invoices">
              <p>
                Number of Invoice(s): <span>{budgetData?.length}</span>
              </p>
            </div>

            <div className="invoice-detail">
              <Slider {...settings}>
                {budgetData?.map((item) => {
                  const {
                    id,
                    fields: {
                      summary,
                      customfield_11903,
                      customfield_11904,
                      customfield_11905,
                      description,
                    },
                  } = item;

                  return (
                    <Box key={id} p={1}>
                      <Paper
                        elevation={0}
                        sx={{
                          alignItems: "stretch",
                          gap: 2,
                          padding: 1,
                          backgroundColor: "rgba(254, 200, 77, 0.2)",
                        }}
                      >
                        <h3>
                          {summary}
                          {description !== null && (
                            <Tooltip
                              title={
                                <JiraDescription data={description?.content} />
                              }
                              placement="top"
                            >
                              <IconButton
                                size="small"
                                style={{ marginLeft: 2 }}
                              >
                                <InformationCircleIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                          <span>{customfield_11905?.value}</span>
                        </h3>
                        <div className="flex">
                          <p>
                            <b> Amount: </b>
                            {numberWithCommas(customfield_11904) || 0} (THB)
                          </p>
                          <p>
                            <b>Invoice Date: </b>
                            {formatDate(customfield_11903) || ""}
                          </p>
                        </div>
                      </Paper>
                    </Box>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Budget;
