import React, { useState } from "react";
import {
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  CheckBadgeIcon,
  Cog6ToothIcon,
  ClipboardDocumentListIcon,
  CogIcon,
  PaintBrushIcon,
  BuildingOffice2Icon,
  StarIcon,
  PauseCircleIcon,
} from "@heroicons/react/24/outline";
import StackedBarChart from "../../../shared/StackedBarChart";
import generateRandomColor from "../../../utils/generateRandomColor";
import { SUMMARY_2024, RPA_2024 } from "../../../constants/localDev2024";

function Summary2024() {
  const [selectedDetail, setSelectedDetail] = useState("CLM");
  const createDataset = (label, data) => ({
    label,
    data,
    backgroundColor: generateRandomColor(),
  });

  const chartData = {
    labels: ["CLM", "Product/Fulfillment", "HR", "Store", "O2O"],
    datasets: [
      createDataset("NON - SDT", [21, 1, 0, 1, 1]),
      createDataset("SDT", [13, 2, 2, 1, 2]),
    ],
  };

  const handleChange = (event) => {
    setSelectedDetail(event.target.value);
  };

  const renderTable = () => {
    const detail = SUMMARY_2024[selectedDetail] || { SDT: [], NON_SDT: [] };
    const { SDT = [], NON_SDT = [] } = detail;
    const maxRows = Math.max(SDT.length, NON_SDT.length);

    const columns = Object.keys(RPA_2024);
    const columnTotals = columns.map((column) => RPA_2024[column].length);
    const maxRowsRPA = Math.max(
      ...Object.values(RPA_2024).map((items) => items.length)
    );

    if (selectedDetail === "RPA") {
      return (
        <TableContainer className="mt-4">
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={column} style={{ fontWeight: "bold" }}>
                    {`${column} (${columnTotals[index]})`}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: maxRowsRPA }).map((_, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{
                    backgroundColor:
                      rowIndex % 2 === 0 ? "rgb(227, 227, 227)" : "white",
                  }}
                >
                  {columns.map((column) => (
                    <TableCell key={`${column}-${rowIndex}`}>
                      {RPA_2024[column][rowIndex] || ""}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      return (
        <TableContainer className="mt-4">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>SDT</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>NON-SDT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: maxRows }).map((_, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? "rgb(227, 227, 227)" : "white",
                  }}
                >
                  <TableCell>{detail.SDT[index] || ""}</TableCell>
                  <TableCell>{detail.NON_SDT[index] || ""}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  };

  const headCounts =
    selectedDetail === "RPA"
      ? 4
      : SUMMARY_2024[selectedDetail]?.Head_Counts ?? "N/A";

  return (
    <>
      <Grid item xs={12} md={6}>
        <div className="summary-item project-sum">
          <div className="flex">
            <h4 className="no-border">
              <StarIcon />
              Project Summary
            </h4>
          </div>
          <div className="detail-item flex no-border">
            <div className="item ontrack">
              <CheckBadgeIcon />
              <p>Completed</p>
              <h3>44</h3>
            </div>

            <div className="item inprogress">
              <Cog6ToothIcon />
              <p>SDT</p>
              <h3>32</h3>
            </div>

            <div className="item at-risk">
              <ClipboardDocumentListIcon />
              <p>Carried Over</p>
              <h3>7</h3>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} md={6}>
        <div className="summary-item">
          <div className="flex">
            <h4 className="no-border">
              <BuildingOffice2Icon />
              By Domain
            </h4>
          </div>

          {chartData && (
            <div className="detail-item flex no-border">
              <StackedBarChart data={chartData} />
            </div>
          )}
        </div>
      </Grid>

      <Grid item xs={12} md={2}>
        <div className="summary-item sdt-container">
          <div className="flex">
            <h4 className="no-border center">
              <CogIcon />
              RPA
            </h4>
          </div>

          <div className="detail-item center no-border">
            <span>59</span>
            <p className="sub">New Business Flows</p>
            <p className="small">
              Finance / Store / Fulfillment / CLM / O2O / IT
            </p>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} md={2}>
        <div className="summary-item sdt-container">
          <div className="flex">
            <h4 className="no-border center">
              <PaintBrushIcon />
              UXUI
            </h4>
          </div>

          <div className="detail-item center no-border">
            <span>4</span>
            <p className="sub">UX Research Projects</p>
            <p className="small">CLM / O2O</p>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} md={8}>
        <div className="summary-item sdt-container">
          <div className="flex">
            <h4 className="no-border center">
              <ClipboardDocumentListIcon />
              Malaysia Projects
            </h4>
          </div>

          <div className="detail-item flex no-border">
            <div className="item ontrack">
              <CheckBadgeIcon />
              <p>Completed</p>
              <h3>3</h3>
            </div>

            <div className="item inprogress">
              <Cog6ToothIcon />
              <p>UAT</p>
              <h3>2</h3>
            </div>

            <div className="item at-risk">
              <PauseCircleIcon />
              <p>On-Hold</p>
              <h3>1</h3>
            </div>

            <div className="item at-risk">
              <ClipboardDocumentListIcon />
              <p>No Requirement</p>
              <h3>1</h3>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} md={12}>
        <div className="summary-item sdt-container">
          <div className="flex">
            <h4 className="no-border line-height40">
              <ClipboardDocumentListIcon />
              Completed Project Detail
            </h4>

            {headCounts !== "" && (
              <div className="head-counts line-height40">
                Head Counts: <span>{headCounts}</span>
              </div>
            )}

            <h4 className="no-border">
              <span className="flex no-border filters-wrapper">
                <FormControl
                  className="dropdown"
                  variant="outlined"
                  margin="normal"
                  size="small"
                >
                  <InputLabel id="domain-filter-label">Domain</InputLabel>
                  <Select
                    labelId="domain-filter-label"
                    value={selectedDetail}
                    onChange={handleChange}
                    label="Filter by Domain"
                  >
                    {Object.keys(SUMMARY_2024).map((key) => (
                      <MenuItem key={key} value={key}>
                        {key.replace(/_/g, " ")}
                      </MenuItem>
                    ))}
                    <MenuItem key={10} value={"RPA"}>
                      RPA
                    </MenuItem>
                  </Select>
                </FormControl>
              </span>
            </h4>
          </div>

          <div className="detail-item no-border scroll">{renderTable()}</div>
        </div>
      </Grid>
    </>
  );
}

export default Summary2024;
