export const SUMMARY_2024 = {
  CLM: {
    SDT: [
      "Account Recovery",
      "B2B specified Loyalty Scheme",
      "BZB - Coin Earn at Tenants in Mall and Key Partners - (Mall Loyalty)",
      "Missions Campaign - Shopping Txn",
      "Referral Code",
      "Reward Store - Revise Coupon Mechanic",
      "SessionM - Improve capability",
      "TMN & Pay Next Extra report",
      "3 Prasarn Program",
      "Point Exchange - Standard API",
      "UXUI Enhancement",
      "Mobile App Enhancement",
      "SFMC SDK/Collect Code",
    ],
    NON_SDT: [
      "Speed up coin notification",
      "Shop Marathon",
      "BZB Offer on LINE Web Loyalty",
      "Flex Queue Replacement",
      "LINE Point History",
      "LMP / Credit Score",
      "Favorite Coupon",
      "Parner Portal",
      "Agoda - Point to Discount",
      "Gamification (3 Games)",
      "Gamification (1 AR Game)",
      "BZB - Coin Earn at Tenants in Mall and Key Partners - (K-Artist)",
      "Qualtrics - Loyalty Integration (R10)",
      "Consent Master",
      "Amaze Account Integration / Binding",
      "Amaze Point Exchange (on Amaze)",
      "Amaze Point Exchange SDK",
      "Harmony - Campany Changes",
      "Harmony - Make 1st consent optional",
      "Move SMS Gateway to EGG SMS (OTP)",
      "Public Coupon API Issuance",
    ],
    Head_Counts: 32,
  },
  Product_and_Fulfillment: {
    SDT: ["Order Review for CMA"],
    NON_SDT: ["Vendor Booking System", "New North DC"],
    Head_Counts: 3,
  },
  Finance_HR_Property: {
    SDT: ["WFM Rollout", "iHiring (Digitalized Talent Acquisition)"],
    NON_SDT: [],
    Head_Counts: 10,
  },
  Store: {
    SDT: ["R10/SIM"],
    NON_SDT: ["Harmony"],
    Head_Counts: 10,
  },
  O2O: {
    SDT: ["Amaze"],
    NON_SDT: ["E-Tax Marketplace", "Lotus's Eat", "Coupon API (Phoenix)"],
    Head_Counts: "",
  },
};

export const RPA_2024 = {
  Finance: [
    "O2O RPA [Data Cube] | 2C2P HLE Installment",
    "Tender Schedule & Daily Cash",
    "O2O RPA [Data Cube] | Lotus's EATS Delivery",
    "[AP Trade] DC Format",
    "Generate AR Report - Foodcourt Netting",
    "Generate AR Report - Auto reconcile invoice generate (GP)",
    "Generate AR Report - Auto check utility generate",
    "Fix asset - BI (Report 18 sources)",
    "Bank Transfer to ERP",
    "Bank reconcile data_sim (for fix Food court netting AR12B that failed)",
    "Accrued Utility Income mall",
    "Paid out",
    "Automate Reminder",
    "Deposit Refund Report",
    "SCB Anywhere",
    "ProjectVatSuspense (SupplierMaster)",
    "ProjectVatSuspense (Opex and Capex)",
    "ProjectPAudit (Opex and Capex)",
    "ProjectVatReport (Opex and Capex)",
    "True Money Auto JV",
    "KBank Vending Machine Auto JV",
    "True Vending Machine Auto JV",
    "Procurement Reports",
    "Download Bank Statement - CR (Add missing accounts)",
    "True money auto JV - CR (Add missing total)",
    "ACCT O2O - Data cube",
    "Download Report from Email and Upload",
    "Download AR report for Data Cube",
    "True installment auto JV 1 process",
    "Get file from Email - มูลนิธิ",
    "Paid Out - CR - 1 additional report",
    "Auto-mail follow up outstanding debt",
    "Credit Notes - Simplicity",
    "Auto JV Bank Transfer (Python)",
  ],
  Store: [
    "R10 DI Inbound",
    "Auto API for Coupon - R10",
    "Harmony - Check receipt on POS",
    "Help Support Stock Transfer Issue",
    "Import Customer Data to FFTI - Company",
    "Special Coin R10",
    "R10 Non-smart Coupon 96,92",
    "R10 DI sale out bound",
    "Python QueryTool",
  ],
  "Product and Fulfillment": [
    "Move Files from BY to SharePoint",
    "BY FNR: Report Menu",
    "Neilson Data Load",
  ],
  Monitoring: [
    "Sales TXN",
    "CPFM Coins",
    "Foodcourt TMN",
    "Session M",
    "[Dashboard] Inventory Movement - SL > IL > SOA > SIMs > RIB > RMS",
    "[Dashboard] Sales - SL > RTS > RESA > ERP (Tender)",
    "[Dashboard] Inventory Movement - WMS > Adapter > RMS > RIB > SIM",
    "[RECON SIM 011] ERP -> Sales by StoreLine",
    "[RECON003] PO",
  ],
  IT: ["RMS Airflow Outbound -> Sharepoint", "Service Desk - Download Report"],
  O2O: ["Lotus Eat"],
};
