import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  Fab,
  Paper,
  TextField,
  Button,
  Typography,
  IconButton,
  Slide,
  Fade,
} from "@mui/material";
import {
  Message as MessageIcon,
  Close as CloseIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import MessageContent from "./MessageContent";
import axios from "axios";

const FloatingChat = ({ position = "right", data = [] }) => {
  const dataContext = useMemo(
    () =>
      `Here's the current data to analyze: ${JSON.stringify(data.slice(0, 5))}`,
    [data]
  );

  const initialMessages = useMemo(
    () => [
      {
        role: "system",
        content: `You are a senior PMO (Project Management Office) professional with 15+ years of experience in enterprise software development. Your expertise includes:
      - Data analysis and reporting with project management metrics
      - Large-scale Jira administration and customization (1000+ users)
      - Portfolio and resource optimization
      - Agile/Scrum methodologies for enterprise projects
      
      Based on the following data: 
      ${dataContext}
      
      Provide actionable advice incorporating industry best practices, addressing enterprise-scale challenges, and offering practical, implementable solutions. Focus on key areas of improvement and avoid generic responses.`,
      },
    ],
    [dataContext]
  );

  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState(initialMessages);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const getChatCompletion = useCallback(async (updatedMessages) => {
    try {
      const response = await axios.post(
        process.env.GATSBY_LLM_URL,
        {
          model: "default",
          messages: updatedMessages,
          temperature: 0.7,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.GATSBY_LLM_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching chat completion:", error);
      throw new Error("Failed to fetch chat response");
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!userInput.trim() || isLoading) return;

    const newMessage = { role: "user", content: userInput.trim() };
    const updatedMessages = [...messages, newMessage];

    setIsLoading(true);
    setMessages(updatedMessages);

    try {
      const data = await getChatCompletion(updatedMessages);
      const assistantReply =
        data?.choices?.[0]?.message?.content || "No response received.";
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", content: assistantReply },
      ]);
    } catch {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "assistant",
          content: "Error fetching response from the server.",
        },
      ]);
    } finally {
      setUserInput("");
      setIsLoading(false);
    }
  }, [userInput, isLoading, messages, getChatCompletion]);

  return (
    <>
      <Fade in={!isOpen}>
        <Fab
          color="primary"
          aria-label="chat"
          onClick={() => setIsOpen(true)}
          sx={{
            position: "fixed",
            bottom: 20,
            [position]: 20,
          }}
        >
          <MessageIcon />
        </Fab>
      </Fade>

      <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
        <Paper
          elevation={4}
          sx={{
            position: "fixed",
            bottom: 20,
            [position]: 20,
            width: 360,
            height: 500,
            display: "flex",
            flexDirection: "column",
            zIndex: 999,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: "primary.main",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Chat Assistant</Typography>
            <IconButton
              color="inherit"
              onClick={() => setIsOpen(false)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              p: 2,
              backgroundColor: "#f5f5f5",
            }}
          >
            {messages
              .filter((msg) => msg.role !== "system")
              .map((message, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent:
                      message.role === "user" ? "flex-end" : "flex-start",
                    mb: 2,
                  }}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      p: 2,
                      maxWidth: "80%",
                      backgroundColor:
                        message.role === "user" ? "#1976d2" : "#fff",
                      borderRadius: 2,
                      "& pre": {
                        margin: "4px 0",
                        borderRadius: 1,
                        maxWidth: "100%",
                        overflow: "auto",
                      },
                    }}
                  >
                    <MessageContent
                      content={message.content}
                      isUser={message.role === "user"}
                    />
                  </Paper>
                </Box>
              ))}
            <div ref={messagesEndRef} />
          </Box>

          <Box sx={{ p: 2, backgroundColor: "background.paper" }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <TextField
                size="small"
                placeholder="Type a message..."
                variant="outlined"
                fullWidth
                multiline
                maxRows={3}
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
                disabled={isLoading}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isLoading}
                sx={{
                  minWidth: "unset",
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                }}
              >
                <SendIcon />
              </Button>
            </Box>
          </Box>
        </Paper>
      </Slide>
    </>
  );
};

export default React.memo(FloatingChat);
